import { Box, Typography, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { buttonMarginSpace, buttonWidth } from "../../constants/form";
import { Routes } from "../../../../util/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "../../../../state";
import { closeModal } from "../../../../state/itemPopupModalSlice";
import { useTranslation } from "react-i18next";
import { openNewItemModal } from "../../../../state/itemModalSlice";
import { popupFormWidth } from "./constants";



export default function CreateItemPopupModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
    const { t: tCreate } = useTranslation([], { keyPrefix: "team.Modals.Items.createAndEdit.Create Item" })

    const { openItemPopupModal } = useSelector(({ itemPopupModalState }) => itemPopupModalState);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeModal());
    };

    const handleOpenCreateItemPopupModal = () => {
        handleClose();
        dispatch(openNewItemModal())
    };


    return (
        <Dialog
            open={openItemPopupModal}
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick")
                    return;
                handleClose()
            }}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{ style: { width: popupFormWidth } }}
        >
            <>
                <DialogTitle sx={{ m: 0, p: 1 }}>{tCreate("Create Item")}</DialogTitle>
                <IconButton color="primary" sx={{ position: "absolute", right: 4, top: 4 }} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ p: 1 }} >
                    <Typography align="center">{tCreate("To avoid duplicate, it is suggested to search for existing items.")}</Typography>
                    <Typography align="center">{tCreate("Search for existing items?")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Box width="100%" sx={{ display: "flex", justifyContent: "end" }}>
                        <Button variant="outlined" color="primary" sx={{ width: buttonWidth, marginRight: buttonMarginSpace }} onClick={handleClose} >{tGlobal("Cancel")}</Button>
                        <Box>
                            <Button variant="outlined" color="primary" sx={{ width: buttonWidth, marginRight: buttonMarginSpace }} onClick={() => {
                                navigate(Routes.HOME_ITEMS)
                                handleClose()
                            }}>{tGlobal("Search")}</Button>
                            <Button variant="contained" color="primary" sx={{ width: buttonWidth }} onClick={() => { handleOpenCreateItemPopupModal() }}>{tGlobal("Create")}</Button>

                        </Box>
                    </Box>
                </DialogActions>
            </>
        </Dialog >
    );
}
