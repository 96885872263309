import * as React from 'react';
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridColumnMenuProps,
  GridColumnMenu,
  GridLoadingOverlayProps,
  LoadingOverlayPropsOverrides,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { CircularProgress, LinearProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { searchCustomers, setSearchInput, setSearchLoading, setSelectedCustomers } from '../../../../state/customerSearchModalSlice';
import { useDispatch, useSelector } from '../../../../state';
import { CustomerSearchInput } from 'orderflow-lambdas';
import { useCallback, useEffect } from 'react';
import { debounce } from '@mui/material/utils';
import SearchByDropdown from './SearchByDropdown';
import { useTranslation } from 'react-i18next';
import hexToRGBA from '../../../../util/converters/hexToRGBA';
import { initialPageSize } from './constants';

interface LoaderProps extends Omit<GridLoadingOverlayProps, 'color'>, LoadingOverlayPropsOverrides {
  color?: string;
}

const Loader = (props: LoaderProps) => {
  return <LinearProgress color={'primary'} />;
};

function CustomToolbar({ pageSize }: { pageSize: number }) {
  const dispatch = useDispatch();
  const { t: tMCS } = useTranslation([], { keyPrefix: 'team.Modals.CustomersSearch' });
  const { searchInput, searchBy, searchLoading ,resp} = useSelector((store) => store.customerSearchModalSlice);
  const { team } = useSelector((store) => store.userSlice);

  const debouncedSearch = useCallback(
    debounce((searchQuery: string) => {
      const payload: CustomerSearchInput = { query: searchQuery, searchBy, size: pageSize, page: 0 };
      dispatch(searchCustomers(payload));
    }, 1500),
    [searchBy]
  );

  if (team === 'init' || team === 'loading') return null;

  return (
    <GridToolbarContainer>
      <SearchByDropdown />
      <TextField
        value={searchInput}
        onChange={(e) => {
          dispatch(setSearchLoading(true));
          dispatch(setSearchInput(e.currentTarget.value));
          debouncedSearch(e.currentTarget.value);
        }}
        id='filled-basic'
        label={tMCS('Search for customers...')}
        variant='outlined'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchOutlinedIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <React.Fragment>
              {searchLoading ? <CircularProgress color='inherit' size={20} /> : null}
            </React.Fragment>
          ),
        }}
      />
    </GridToolbarContainer>
  );
}

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsCustomer: null,
        columnMenuFilterCustomer: null,
      }}
    />
  );
}

interface TableProps {
  multiSelect: boolean;
}

export default function Table({ multiSelect }: TableProps) {
  const dispatch = useDispatch();
  const { t: tGC } = useTranslation([], { keyPrefix: 'team.Global.Common' });
  const { t: tMIS } = useTranslation([], { keyPrefix: 'team.Modals.CustomersSearch' });
  const { resp, searchLoading, searchInput, searchBy } = useSelector((store) => store.customerSearchModalSlice);

  // 2) Handle row selection
  const handleSelectionChange = (newSelectionModel: GridRowSelectionModel) => {
    // If single selection is desired but newSelectionModel has >1 items,
    // keep only the last item.
    let finalSelectionModel = newSelectionModel;
    if (!multiSelect && newSelectionModel.length > 1) {
      const lastId = newSelectionModel[newSelectionModel.length - 1];
      if (lastId !== undefined) {
        finalSelectionModel = [lastId];
      }
    }

    // Filter the rows to get the full objects of the selected customers
    const rows = resp ? resp.results : [];
    const selected = rows.filter((row) => finalSelectionModel.includes(row.CustomerId));
    dispatch(setSelectedCustomers(selected));
  };

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: tMIS('Customer Code'),
      type: 'string',
      flex: 1,
      valueGetter: (value, row) => {
        return row.code;
      },
      renderCell: (params: GridRenderCellParams<any>) => <span>{params.value}</span>,
    },
    {
      field: 'companyName',
      headerName: tGC('Company Name'),
      flex: 1,
      valueGetter: (value, row) => {
        return row.details.companyName;
      },
      renderCell: (params: GridRenderCellParams<any>) => <span>{params.value}</span>,
    },
    {
      field: 'contact',
      headerName: tGC('Contact'),
      flex: 1,
      valueGetter: (value, row) => {
        return row.billing?.contactName;
      },
      renderCell: (params: GridRenderCellParams<any>) => <span>{params.value}</span>,
    },
    {
      field: 'city',
      headerName: tGC('City'),
      flex: 1,
      valueGetter: (value, row) => {
        return row.details.city;
      },
      renderCell: (params: GridRenderCellParams<any>) => <span>{params.value}</span>,
    },
    {
      field: 'telephone',
      headerName: tGC('Tel. No.'),
      flex: 1,
      valueGetter: (value, row) => {
        return row.details.phone;
      },
      renderCell: (params: GridRenderCellParams<any>) => <span>{params.value}</span>,
    },
    {
      field: 'email',
      headerName: tGC('Email'),
      flex: 1,
      valueGetter: (value, row) => {
        return row.details.email;
      },
      renderCell: (params: GridRenderCellParams<any>) => <span>{params.value}</span>,
    },
  ];

  return (
    <DataGridPro
      sx={{
        border: 'none',
        padding: '1rem',
        '.MuiDataGrid-row:nth-of-type(even):not(.Mui-selected)': {
          backgroundColor: '#ECECEC',
        },
        '.MuiDataGrid-row.Mui-selected': {
          backgroundColor: (theme) => hexToRGBA(theme.palette.primary.main, 0.19),
        },
        '.MuiDataGrid-row.Mui-selected:hover': {
          backgroundColor: (theme) => hexToRGBA(theme.palette.primary.main, 0.19),
        },
        '.MuiDataGrid-scrollbar--horizontal': { display: 'none' },
        '.MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
      }}
      rows={
        resp
          ? resp.results.map((customer) => ({
            ...customer,
            id: customer.CustomerId,
            tex: customer.details?.phone,
          }))
          : []
      }
      onRowSelectionModelChange={handleSelectionChange}
      checkboxSelection={multiSelect} // show checkboxes only if multiSelect=true
      disableRowSelectionOnClick={multiSelect ? true : false}
      loading={searchLoading}
      columns={columns}
      pagination
      paginationMode='server'
      paginationModel={{
        page: resp?.page || 0,
        pageSize: resp?.size || 0
      }}
      rowCount={resp?.total || 0}
      pageSizeOptions={[initialPageSize, 25, 50,100]}
      onPaginationModelChange={(newModel) => {
        const payload: CustomerSearchInput = {
          query: searchInput,
          searchBy,
          page: newModel.page,
          size: newModel.pageSize,
        };
        dispatch(searchCustomers(payload));
      }}
      pinnedColumns={{ right: ['action'] }}
      disableColumnMenu
      autoHeight={false}
      slots={{
        loadingOverlay: Loader,
        toolbar: () => <CustomToolbar pageSize={resp?.size || initialPageSize} />,
        columnMenu: CustomColumnMenu,
      }}
    />
  );
}
