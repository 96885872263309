import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Box, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, TextField, FormControl, InputLabel, FilledInput, FormHelperText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { buttonWidth, buttonMarginSpace, textFieldProps } from '../constants/form';
import { pushToast, useDispatch, useSelector } from '../../../state';
import { closeModal, setLoading } from '../../../state/systemNumbersModalSlice';
import { getFormikField } from '../../../util/fornik-helpers';
import { TRPCClientError } from '@trpc/client';
import { serverError } from '../constants/errors';
import { client } from '../../../util';
import { Counter } from 'orderflow-lambdas';

const initialValues: Counter = {
    TeamId: '',
    customers: {},
    invoices: {},
    vendors: {},
    orders: {},
    items: {},
    updatedAt: 0,
    createdAt: 0,
    orderNumber: 0,
    estimateNumber: 0,
    packingSlipNumber: 0
};

export default function SystemNumbersModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: 'team.Global.Buttons' })
    const { t: tMS } = useTranslation([], { keyPrefix: 'team.Modals.SystemNumbers' })

    const dispatch = useDispatch();
    const { openSystemNumbersModal, loading } = useSelector(({ systemNumbersModalSlice }) => systemNumbersModalSlice);

    const [schema, setSchema] = useState(
        Yup.object().shape({
            orderNumber: Yup.number(),
            packingSlipNumber: Yup.number(),
            estimateNumber: Yup.number(),
        })
    );

    const resetState = () => {
        formik.resetForm()
    };

    const handleClose = () => {
        dispatch(closeModal());
        resetState();
    };

    const handleUpdateSystemNumbers = async (payload: Counter) => {
        dispatch(setLoading(true))
        try {
            await client.setSystemNumbers.mutate({
                orderNumber: payload.orderNumber,
                packingSlipNumber: payload.packingSlipNumber,
                estimateNumber: payload.estimateNumber,
            });
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: 'error' }));
            } else {
                dispatch(pushToast({ message: serverError, type: 'error' }));
            }
            return
        }
        dispatch(pushToast({ message: tMS('System numbers are saved!'), type: 'success' }));
        dispatch(setLoading(false))
    }

    // Formik initialization
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: handleUpdateSystemNumbers,
    });

    useEffect(() => {
        if (!openSystemNumbersModal) return;
        const fetchData = async () => {
            dispatch(setLoading(true))
            try {
                const resp = await client.getCounters.query();
                formik.setValues({
                    ...resp
                });
                setSchema(Yup.object().shape({
                    orderNumber: Yup.number().min(resp.orderNumber, `${tMS('Order number must be at least')} ${resp.orderNumber}`),
                    packingSlipNumber: Yup.number().min(resp.packingSlipNumber, `${tMS('Packing slip number must be at least')} ${resp.packingSlipNumber}`),
                    estimateNumber: Yup.number().min(resp.estimateNumber, `${tMS('Estimate number must be at least')} ${resp.estimateNumber}`),
                }));
            } catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: 'error' }));
                } else {
                    dispatch(pushToast({ message: serverError, type: 'error' }));
                }
                handleClose()
            }
            dispatch(setLoading(false))
        };
        fetchData();
    }, [openSystemNumbersModal]);

    return (
        <Dialog
            open={openSystemNumbersModal}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick')
                    return;
                handleClose()
            }}
            maxWidth='lg'
            scroll='paper'
            PaperProps={{ className: 'w-full' }}
        >
            <DialogTitle sx={{ m: 0, p: 1 }}>{'System Numbers'}</DialogTitle  >
            <IconButton disabled={loading} color='primary' sx={{ position: 'absolute', right: 4, top: 4 }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ p: 3 }} >
                {loading ? (
                    <Box height='400px' display='flex' justifyContent='center' alignItems='center'>
                        <CircularProgress size={80} />
                    </Box>
                ) : (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='grid w-full grid-flow-col grid-rows-1 gap-4 py-20 webkit-box'>
                                {/* <div className='flex justify-center'>
                                    <FormControl variant='filled' {...textFieldProps} error={Boolean(getFormikField(formik, 'orderNumber').error)}>
                                        <InputLabel>{'Next Order No.'}</InputLabel>
                                        <FilledInput
                                            name='orderNumber'
                                            type='number'
                                            value={getFormikField(formik, 'orderNumber').value}
                                            onChange={formik.handleChange}
                                        />
                                        <FormHelperText
                                            sx={{ color: getFormikField(formik, 'orderNumber').error ? 'error.main' : 'green' }}
                                        >
                                            {getFormikField(formik, 'orderNumber').error || `You must enter a value higher than ${'sadas'}`}
                                        </FormHelperText>
                                    </FormControl>
                                </div> */}
                                <div className='flex justify-center'>
                                    <TextField variant='filled' {...textFieldProps} label={tMS('Next Order No.')} name='orderNumber' type='number'
                                        error={Boolean(getFormikField(formik, 'orderNumber').error)}
                                        helperText={getFormikField(formik, 'orderNumber').error}
                                        value={getFormikField(formik, 'orderNumber').value} onChange={formik.handleChange}
                                    />
                                </div>
                                <div className='flex justify-center'>
                                    <TextField variant='filled' {...textFieldProps} label={tMS('Next Packing Slip No.')} name='packingSlipNumber' type='number'
                                        error={Boolean(getFormikField(formik, 'packingSlipNumber').error)}
                                        helperText={getFormikField(formik, 'packingSlipNumber').error}
                                        value={getFormikField(formik, 'packingSlipNumber').value} onChange={formik.handleChange}
                                    />
                                </div>
                                <div className='flex justify-center'>
                                    <TextField variant='filled' {...textFieldProps} label={tMS('Next Estimate No.')} name='estimateNumber' type='number'
                                        error={Boolean(getFormikField(formik, 'estimateNumber').error)}
                                        helperText={getFormikField(formik, 'estimateNumber').error}
                                        value={getFormikField(formik, 'estimateNumber').value} onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Box >
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Box width='100%' sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button disabled={loading} variant='outlined' color='primary' sx={{ width: buttonWidth, marginRight: buttonMarginSpace }} onClick={handleClose}>{tGlobal('Cancel')}</Button>
                    <Button disabled={loading} variant='contained' color='primary' sx={{ width: buttonWidth }} onClick={() => {
                        formik.submitForm()
                    }}>{loading ? <CircularProgress size={20} /> : tGlobal('Save')}</Button>
                </Box>
            </DialogActions>
        </Dialog >
    );
}
