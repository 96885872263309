import React, { useState } from "react";
import { Box, Typography, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { pushToast, useDispatch, useSelector } from "../../../../state";
import { closeModal, deleteItem } from "../../../../state/itemModalSlice";
import { useTranslation } from "react-i18next";
import { TRPCClientError } from "@trpc/client";
import { serverLatencyMessage } from "../../constants/messages";
import { serverError } from "../../constants/errors";
import { buttonMarginSpace } from "../../constants/form";

export default function DeleteItemModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
    const { t: tDelete } = useTranslation([], { keyPrefix: "team.Modals.Items.delete" })
    const dispatch = useDispatch();
    const { deleteItemModal, itemToDelete, deleteCallback, loading } = useSelector(({ itemModalSlice }) => itemModalSlice);

    const handleClose = () => {
        dispatch(closeModal());
    };

    const confirmDelete = async () => {
        if (!itemToDelete) return
        try {
            await dispatch(deleteItem(itemToDelete.ItemId));
            if (deleteCallback) deleteCallback(itemToDelete)
            dispatch(pushToast({
                message: `Item ${itemToDelete?.details.vendorItemNumber || ""} removed`,
                additionalInfo: serverLatencyMessage,
                type: "success", duration: 7000
            }));
            handleClose()
        } catch (error) {
            console.error("error", error);
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
    }

    return (
        <Dialog
            open={deleteItemModal}
            maxWidth="sm"
            fullWidth
            scroll="paper"
        >
            <>
                <DialogTitle sx={{ m: 0, p: 1 }}>{tDelete("title")}</DialogTitle>
                <IconButton disabled={loading} color="primary" sx={{ position: "absolute", right: 4, top: 4 }} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ p: 2 }} >
                    <Typography align="center">
                        {tDelete("body")}
                        <span style={{ fontWeight: 600 }}>
                            {itemToDelete?.details.vendorItemNumber}
                        </span>
                    </Typography>
                    <Typography align="center">{tDelete("warning")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Box width="100%" sx={{ display: "flex", justifyContent: "end" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ marginRight: buttonMarginSpace }}
                            onClick={handleClose}
                            disabled={loading}
                        >
                            {tGlobal("Cancel")}
                        </Button>
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={confirmDelete}
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={20} /> : tDelete("confirm")}
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </>
        </Dialog >
    );
}
