import * as React from 'react';
import Box from '@mui/material/Box';
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  GridColumnMenuProps,
  GridColumnMenu,
  GridLoadingOverlayProps,
  LoadingOverlayPropsOverrides,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { Button, CircularProgress, debounce, LinearProgress } from '@mui/material';
import HeaderElipse from './headerElipse';
import RowButton from './rowButton';
import DropDown from './dropDown';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useDispatch, useSelector } from '../../state';
import { setSearchInput, searchOrders, setLoading } from '../../state/orderBrowseSlice';
import { OrderSearchInput } from 'orderflow-lambdas';
import { topNavBarHeight } from '../NavBars/constants';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { openOrderModal } from '../../state/orderModalSlice';

interface LoaderProps extends Omit<GridLoadingOverlayProps, 'color'>, LoadingOverlayPropsOverrides {
  color?: string;
}

const Loader = (props: LoaderProps) => {
  return <LinearProgress color={'primary'} />;
}


function CustomToolbar() {
  const dispatch = useDispatch()
  const { t: tGB } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
  const { t: tCO } = useTranslation([], { keyPrefix: 'team.Components.OrderBrowseTable' })
  const { searchInput, searchBy, loading } = useSelector((store) => store.orderBrowseSlice)
  const { team } = useSelector((store) => store.userSlice)


  const debouncedSearch = useCallback(
    debounce((searchQuery: string) => {
      const payload: OrderSearchInput = { query: searchQuery, searchBy, size: 100, page: 0 }
      dispatch(searchOrders(payload))
    }, 1500),
    [searchBy]
  );

  if (team === 'init' || team === 'loading') return null

  return (
    <GridToolbarContainer>
      <DropDown />
      <TextField
        value={searchInput}
        onChange={(e) => {
          dispatch(setLoading(true))
          dispatch(setSearchInput(e.currentTarget.value))
          debouncedSearch(e.currentTarget.value)
        }}
        id='filled-basic'
        label={tCO('Search for Orders...')}
        variant='outlined'
        InputProps={{
          startAdornment: <InputAdornment position='start'><SearchOutlinedIcon /></InputAdornment>,
          endAdornment: <React.Fragment>
            {loading ? <CircularProgress color='inherit' size={20} /> : null}
          </React.Fragment>
        }}
      />
      <Button
        variant='outlined'
        color='primary'
        onClick={() => {
          const payload: OrderSearchInput = { query: searchInput, searchBy: 'companyName', size: 100, page: 0 }
          dispatch(searchOrders(payload))
        }}
      >{tGB('Search')}</Button>
      <Box sx={{ flexGrow: 1 }} />
      <button
        className='text-blue-600 border-0 bg-inherit hover:cursor-pointer' onClick={() => { dispatch(openOrderModal()) }}>
        {tGB('+ ADD')}</button>
      <GridToolbarExport
        slotProps={{
          tooltip: { title: 'Export data' },
        }}
      />
      <HeaderElipse />
    </GridToolbarContainer>
  );
}

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsOrder`
        columnMenuColumnsOrder: null,
        columnMenuFilterOrder: null
      }}
    />
  );
}

export default function DataTables() {
  const dispatch = useDispatch();
  const { results, loading } = useSelector((store) => store.orderBrowseSlice);
  const { team } = useSelector((store) => store.userSlice);
  const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
  const { t: tCO } = useTranslation([], { keyPrefix: 'team.Components.OrderBrowseTable' })

  const initialResults = async () => {
    if (team === 'init' || team === 'loading') return null
    const payload: OrderSearchInput = { query: '', searchBy: 'companyName', size: 100, page: 0 }
    await dispatch(searchOrders(payload))
  }

  React.useEffect(() => {
    initialResults()
  }, [])
  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: tCO('Order No.'),
      type: 'string',
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params.value}
        </span>
      )
    },
    {
      field: 'createdAt',
      headerName: tGC('Date Created'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params.value}
        </span>
      )
    },
    {
      field: 'companyName',
      headerName: tGC('Customer'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {params.value}
        </span>
      )
    },
    {
      field: 'customerCode',
      headerName: tGC('Customer PO'),
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>
          {JSON.stringify(params.value)}
        </span>
      )
    },
    {
      field: 'action',
      headerName: '',
      width: 55,
      resizable: false,
      sortable: false,
      renderCell: params => {
        return <RowButton order={params.row.order}></RowButton>
      },
    }
  ];

  return (
    <div style={{ height: `calc(100vh - ${topNavBarHeight} - 250px)` }}>
      <DataGridPro
        sx={{
          border: 'none', padding: '1rem', '.MuiDataGrid-row:nth-of-type(even)': {
            backgroundColor: '#ECECEC;',
          }, '.MuiDataGrid-scrollbar--horizontal': { display: 'none' }, '.MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' }
        }}
        rows={results ? results.results.map((result) => {
          return {
            id: result.OrderId,
            code: result.code,
            createdAt: result.createdAt,
            companyName: result.customer.companyName,
            customerCode: result.customer.code,
            unit: result.shipMethod,
            order: result
          }
        }) : []}

        loading={loading}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pinnedColumns={{ right: ['action'] }}
        pageSizeOptions={[1]}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnSorting
        disableColumnMenu
        autoHeight={false}
        slots={{
          loadingOverlay: Loader,
          toolbar: CustomToolbar,
          columnMenu: CustomColumnMenu
        }}
      />
    </div>
  );
}