import { Box, Typography, TextField, Grid, MenuItem, Divider, Stack, InputAdornment, Link } from '@mui/material';
import type { Customer, OrderCreateInput, PaymentTerms, ShipMethods, } from 'orderflow-lambdas';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { gridPaymentItemProps } from './constants';
import { gridItemProps, textFieldProps, textFieldSelectProps } from '../../constants/form';
import dayjs, { Dayjs } from 'dayjs';
import { FormikProps } from 'formik';
import { getFormikField } from '../../../../util/fornik-helpers';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../../../state';
import SelectSearchCustomer from '../../../utils/SelectSearchCustomer';
import { closeModal, openOrderModal, OrderModalMode } from '../../../../state/orderModalSlice';
import { openSearchCustomerModal } from '../../../../state/customerSearchModalSlice';
import { openNewCustomerModal } from '../../../../state/customerModalSlice';

export default function OrderDetails({
    formik,
    paymentTerms,
    shipMethods,
    viewMode,
}: {
    formik: FormikProps<OrderCreateInput>;
    paymentTerms: PaymentTerms[];
    shipMethods: ShipMethods[];
    viewMode: boolean;
}): JSX.Element {
    const { t: tGC } = useTranslation([], { keyPrefix: 'team.Global.Common' })
    const { t: tGB } = useTranslation([], { keyPrefix: 'team.Global.Buttons' })
    const { t: tAddO } = useTranslation([], { keyPrefix: 'team.Modals.Orders.createAndEdit.Add Order' })

    const dispatch = useDispatch()

    const { mode } = useSelector(({ orderModalSlice }) => orderModalSlice);

    const orderDate = formik.values.orderDate != 0 ? dayjs.unix(formik.values.orderDate) : null;

    const handleChangeOrderDate = (newValue: Dayjs | null) => {
        // Convert the new date value to Unix time (seconds since the Unix Epoch)
        const unixTime = newValue ? newValue.unix() : null;

        // Update Formik's state with Unix time
        formik.setFieldValue('orderDate', unixTime);
    };

    const estimatedShipDate = formik.values.estimatedShipDate != 0 ? dayjs.unix(formik.values.estimatedShipDate) : null;

    const handleChangeEstShipDate = (newValue: Dayjs | null) => {
        const unixTime = newValue ? newValue.unix() : null;
        formik.setFieldValue('estimatedShipDate', unixTime);
    };

    const deadline = formik.values.deadline != 0 ? dayjs.unix(formik.values.deadline) : null;

    const handleChangeDeadline = (newValue: Dayjs | null) => {
        const unixTime = newValue ? newValue.unix() : null;
        formik.setFieldValue('deadline', unixTime);
    };

    const onImport = (selectedCustomers: Customer[]) => {
        const customer = selectedCustomers.at(0); // This safely returns undefined if empty
        if (!customer) return;

        formik.setFieldValue('customer.code', customer.code);
        formik.setFieldValue('customer.CustomerId', customer.CustomerId);
        formik.setFieldValue('customer.companyName', customer.details.companyName);
        dispatch(openOrderModal());
    };

    const onCancel = () => {
        dispatch(openOrderModal());
    };

    const openBrowseCustomerModal = () => {
        dispatch(closeModal())
        dispatch(openSearchCustomerModal({ onImport, onCancel, multiSelect: false }))
    };


    const newCustomerCallBack = async (customer: Customer | undefined): Promise<void> => {
        dispatch(openOrderModal());
        if (!customer) return;

        formik.setFieldValue('customer.code', customer.code);
        formik.setFieldValue('customer.CustomerId', customer.CustomerId);
        formik.setFieldValue('customer.companyName', customer.details.companyName);
    };

    const openCreateCustomerModal = () => {
        dispatch(closeModal())
        dispatch(openNewCustomerModal({ createCallback: newCustomerCallBack }))
    };

    const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('code', event.target.value);
    };

    const handleChangeCustomer = (customer: Customer | null) => {
        if (customer != null) {
            formik.setFieldValue('customer.code', customer.code);
            formik.setFieldValue('customer.CustomerId', customer.CustomerId);
            formik.setFieldValue('customer.companyName', customer.details.companyName);
        } else {
            formik.setFieldValue('customer.code', '');
            formik.setFieldValue('customer.CustomerId', '');
            formik.setFieldValue('customer.companyName', '');
        }
    }

    // Reconstructing a partial Customer object from the three fields in formik.
    const currentCustomer = formik.values.customer.companyName
        ? {
            code: formik.values.customer.code,
            CustomerId: formik.values.customer.CustomerId,
            details: {
                companyName: formik.values.customer.companyName,
            },
        } as Customer
        : null; // If companyName doesn't exist, there's no selected customer

    const selectedPaymentTerm = getFormikField(formik, 'paymentTerms').value
    const selectedShipMethod = getFormikField(formik, 'shipMethod').value

    return (
        <>
            <Typography color='primary'>{tAddO('ORDER DETAILS')}</Typography>
            <Divider sx={{ marginBottom: 1 }} />
            <Stack gap={2} direction={'row'} justifyContent={'space-around'} width={'100%'} sx={{ marginY: 2 }}>
                <Stack gap={2} width={'100%'}>
                    <Grid  {...gridItemProps}>
                        <Box {...textFieldProps} >
                            {/* Disabled until quote module is implemented */}
                            <Link
                                color='text.secondary'
                                underline='always'
                                sx={{ cursor: 'pointer' }}
                            >
                                {tAddO('Import Estimate')}
                            </Link>
                        </Box>
                    </Grid>
                </Stack>
                <Stack gap={2} width={'100%'}>
                </Stack>
            </Stack>
            <Stack gap={2} direction={'row'} justifyContent={'space-around'} width={'100%'}>
                <Stack gap={2} width={'100%'}>
                    <Grid {...gridItemProps}>
                        <TextField variant='filled' disabled={viewMode || mode == OrderModalMode.EDIT} {...textFieldProps} label={tAddO('Order No.')} name='code' type='number'
                            error={Boolean(getFormikField(formik, 'code').error)}
                            helperText={getFormikField(formik, 'code').error}
                            value={getFormikField(formik, 'code').value} onChange={handleCodeChange}
                        />
                    </Grid>
                    <Grid {...gridItemProps}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                disabled={viewMode}
                                label={tAddO('Order Date')}
                                {...textFieldProps}
                                value={orderDate}
                                onChange={handleChangeOrderDate}
                                views={['year', 'month', 'day']}
                                slotProps={{
                                    textField: {
                                        error: Boolean(getFormikField(formik, 'orderDate').error),
                                        helperText: getFormikField(formik, 'orderDate').error
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Stack>
                <Stack gap={2} width={'100%'}>
                    <Grid  {...gridItemProps}>
                        <Stack  {...textFieldProps} gap={1}>
                            <SelectSearchCustomer label={tGC('Customer')} onChange={handleChangeCustomer}
                                disabled={viewMode}
                                value={currentCustomer}
                                error={Boolean(getFormikField(formik, 'customer.companyName').error)}
                                helperText={getFormikField(formik, 'customer.companyName').error}
                            />
                            <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
                                <Link
                                    underline='always'
                                    sx={{ cursor: 'pointer' }}
                                    onClick={openBrowseCustomerModal}
                                >
                                    {tAddO('Browse Customer')}
                                </Link>
                                <Link
                                    underline='always'
                                    sx={{ cursor: 'pointer' }}
                                    onClick={openCreateCustomerModal}
                                >
                                    {tGB('Add Customer')}
                                </Link>
                            </Box>
                        </Stack>
                    </Grid>
                </Stack>
            </Stack>
            <Divider sx={{ marginY: 2 }} />
            <Stack gap={2} direction={'row'} justifyContent={'space-around'} width={'100%'}>
                <Stack gap={2} width={'100%'}>
                    <Grid {...gridPaymentItemProps}>
                        <TextField variant='filled' {...textFieldProps} label={tGC('Tax Rate')} name='rate' disabled={viewMode} type='number'
                            error={Boolean(getFormikField(formik, 'rate').error)}
                            helperText={getFormikField(formik, 'rate').error}
                            value={getFormikField(formik, 'rate').value} onChange={formik.handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        %
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid {...gridItemProps}>
                        <TextField variant='filled' {...textFieldProps} label={tAddO('Customer Deposit')} name='customerDeposit' disabled={viewMode} type='number'
                            error={Boolean(getFormikField(formik, 'customerDeposit').error)}
                            helperText={getFormikField(formik, 'customerDeposit').error}
                            value={getFormikField(formik, 'customerDeposit').value} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid {...gridPaymentItemProps}>
                        <TextField variant='filled' {...textFieldProps} {...textFieldSelectProps} label={tAddO('Payment Method')} select name='paymentTerms' disabled={viewMode}
                            error={Boolean(getFormikField(formik, 'paymentTerms').error)}
                            helperText={getFormikField(formik, 'paymentTerms').error}
                            value={Array.isArray(selectedPaymentTerm) && selectedPaymentTerm.length > 0 ? selectedPaymentTerm[0].PaymentTermId : ''}
                            onChange={(event) => {
                                const selectedTerm = paymentTerms.find((pT) => pT.PaymentTermId === event.target.value);
                                formik.setFieldValue('paymentTerms', selectedTerm ? [selectedTerm] : []);
                            }}
                        >
                            <MenuItem value={''}>{tGC('None')}</MenuItem>
                            {paymentTerms.map((pT) => {
                                return (
                                    <MenuItem key={pT.PaymentTermId} value={pT.PaymentTermId}>
                                        {pT.name}
                                    </MenuItem>
                                )
                            }
                            )}
                        </TextField>
                    </Grid>
                </Stack>
                <Stack gap={2} width={'100%'}>
                    <Grid {...gridItemProps}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                disabled={viewMode}
                                label={tAddO('Estimated Ship Date')}
                                {...textFieldProps}
                                value={estimatedShipDate}
                                onChange={handleChangeEstShipDate}
                                views={['year', 'month', 'day']}
                                slotProps={{
                                    textField: {
                                        error: Boolean(getFormikField(formik, 'estimatedShipDate').error),
                                        helperText: getFormikField(formik, 'estimatedShipDate').error
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid {...gridPaymentItemProps}>
                        <TextField variant='filled' {...textFieldProps} {...textFieldSelectProps} label={tAddO('Ship Method')} select name='shipMethod' disabled={viewMode}
                            error={Boolean(getFormikField(formik, 'shipMethod').error)}
                            helperText={getFormikField(formik, 'shipMethod').error}
                            value={selectedShipMethod}
                            onChange={(event) => {
                                const selectedSM = shipMethods.find((sM) => sM.ShipMethodId === event.target.value);
                                formik.setFieldValue('shipMethod', selectedSM ? selectedSM.ShipMethodId : '');
                            }}
                        >
                            <MenuItem value={''}>{tGC('None')}</MenuItem>
                            {shipMethods.map((sM) => {
                                return (
                                    <MenuItem key={sM.ShipMethodId} value={sM.ShipMethodId}>
                                        {sM.method}
                                    </MenuItem>
                                )
                            }
                            )}
                        </TextField>
                    </Grid>
                    <Grid {...gridItemProps}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                disabled={viewMode}
                                label={tAddO('Deadline')}
                                {...textFieldProps}
                                value={deadline}
                                onChange={handleChangeDeadline}
                                views={['year', 'month', 'day']}
                                slotProps={{
                                    textField: {
                                        error: Boolean(getFormikField(formik, 'deadline').error),
                                        helperText: getFormikField(formik, 'deadline').error
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Stack>
            </Stack >
        </>
    );
}