import { Typography, TextField, Radio, MenuItem, Divider, Box, Tooltip, useTheme, IconButton, Button, CircularProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useEffect, useState } from "react";
import { textFieldProps, textFieldSelectProps } from "../../constants/form";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { getFormikField } from "../../../../util/fornik-helpers";
import { useTranslation } from "react-i18next";
import { taxCodeSchema } from "../schemas/TaxCodeSchema";
import { TaxCode, TaxCodeCreateInput, TaxCodeUpdateInput } from "orderflow-lambdas";
import { pushToast, useDispatch, useSelector } from "../../../../state";
import { useFormik } from "formik";
import { client } from "../../../../util";
import { TRPCClientError } from "@trpc/client";
import { serverError } from "../../constants/errors";
import { setLoading } from "../../../../state/settingsModalSlice";

const columnPadding = "0 2px"

const initialValues: TaxCodeCreateInput = {
    codeName: "",
    description: "",
    rate: 0
};

export default function Table(): JSX.Element {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tMSST } = useTranslation([], { keyPrefix: "team.Modals.Settings.SettingsTab.Table" })

    const dispatch = useDispatch();

    const { loading } = useSelector(({ settingsModalSlice }) => settingsModalSlice);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [taxCodes, setTaxCodes] = useState<TaxCode[]>([]);
    const [taxCodeBeforeEdit, setTaxCodeBeforeEdit] = useState<TaxCode>();

    // edit Mode
    const [editMode, setEditMode] = useState<boolean>(false);

    const updateLoading = (value: boolean) => {
        setIsLoading(value)
        dispatch(setLoading(value))
    }

    const handleAddTaxCode = async (payload: TaxCodeCreateInput) => {
        updateLoading(true)
        try {
            const modifiedPayload = {
                ...payload,
                rate: Number(payload.rate), // Explicitly convert to number
            };

            const newTaxcode = await client.createTaxCode.mutate(modifiedPayload);
            setTaxCodes([...taxCodes, newTaxcode]);
            formikTaxCode.resetForm();
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        updateLoading(false)
    };

    const formikTaxCode = useFormik({
        initialValues: initialValues,
        validationSchema: taxCodeSchema,
        onSubmit: handleAddTaxCode,
    });

    const columns: GridColDef[] = [
        { field: "codeName", headerName: tGC("Name"), flex: 1 },
        { field: "description", headerName: tGC("Description"), flex: 1 },
        { field: "rate", headerName: tGC("Rate"), flex: 1 },
        {
            field: "default",
            headerName: tGC("Default"),
            flex: 0.5,
            renderCell: (params) => {
                const selectedTaxCode = taxCodes.find((taxCode) => taxCode.TaxCodeId === params.id);

                if (selectedTaxCode) {

                    const handleCheckDefault = () => {
                        handleChangeDefault(selectedTaxCode)
                    };

                    return (
                        <div>
                            <Radio checked={selectedTaxCode.default} onClick={handleCheckDefault} />
                        </div>
                    )
                } else {
                    // Should never happened but good to have just in case.
                    console.log("Cant find Taxcode on table render with id: ", params.id)
                }

            },
        },
        {
            field: "actions",
            headerName: tGlobal("Actions"),
            width: 80,
            flex: 0.5,
            renderCell: (params) => {
                const handleEdit = () => {
                    handleEditTaxCode(params.id)
                };

                const handleDelete = () => {
                    handleDeleteTaxCode(params.id)
                };

                return (
                    <div>
                        <IconButton onClick={handleEdit}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            },
        }
    ];

    const handleChangeDefault = async (newDefaultTaxCode: TaxCode) => {
        updateLoading(true)
        try {
            const resp = await client.setDefaultTaxCode.mutate(newDefaultTaxCode.TaxCodeId);
            const defaultTC = taxCodes.find((taxCode) => taxCode.default === true);
            if (defaultTC) {
                defaultTC.default = false
                setTaxCodes((prevTaxCodes) =>
                    prevTaxCodes.map((taxCodes) =>
                        taxCodes.TaxCodeId === defaultTC.TaxCodeId ? defaultTC : taxCodes
                    )
                );
            }
            newDefaultTaxCode.default = true
            setTaxCodes((prevTaxCodes) =>
                prevTaxCodes.map((taxCodes) =>
                    taxCodes.TaxCodeId === newDefaultTaxCode.TaxCodeId ? newDefaultTaxCode : taxCodes
                )
            );
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        updateLoading(false)
    }

    const handleDeleteTaxCode = async (id: any) => {
        updateLoading(true)
        try {
            await client.deleteTaxCode.mutate(id);
            // Updating the taxCodes state by filtering out the deleted tax code
            setTaxCodes((prevTaxCodes) =>
                prevTaxCodes.filter((taxCode) => taxCode.TaxCodeId !== id)
            );
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        updateLoading(false)
    }

    const handleEditTaxCode = (id: any) => {
        const selectedTaxCode = taxCodes.find((taxCode) => taxCode.TaxCodeId === id);
        // If the tax code is found, set Formik values
        if (selectedTaxCode) {
            formikTaxCode.setFieldValue("codeName", selectedTaxCode.codeName);
            formikTaxCode.setFieldValue("description", selectedTaxCode.description);
            formikTaxCode.setFieldValue("rate", selectedTaxCode.rate);
            setTaxCodeBeforeEdit(selectedTaxCode)
            setEditMode(true)
        } else {
            console.log("TaxCode not found with ID:", id);
        }
    };

    const handleEditTaxCodeSave = async () => {
        updateLoading(true)
        try {
            if (taxCodeBeforeEdit) {
                const modifiedPayload: TaxCodeUpdateInput = {
                    ...formikTaxCode.values,
                    rate: Number(formikTaxCode.values.rate), // Explicitly convert to number
                    TaxCodeId: taxCodeBeforeEdit.TaxCodeId,
                };
                const updatedTaxCode = await client.updateTaxCode.mutate(modifiedPayload);
                setTaxCodes((prevTaxCodes) =>
                    prevTaxCodes.map((taxCode) =>
                        taxCode.TaxCodeId === updatedTaxCode.TaxCodeId ? updatedTaxCode : taxCode
                    )
                );

                formikTaxCode.resetForm(); // Reset the secondary Formik form
                setTaxCodeBeforeEdit(undefined); // Reset the saved original contact data
                setEditMode(false);
            } else {
                // this should never happened. Before edit save user should always select taxcode that wants to edit
                console.log("TaxCode for edit was not selected.");
            }
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        updateLoading(false)
    };

    const handleEditTaxCodeCancel = () => {
        formikTaxCode.resetForm();
        setEditMode(false)
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const taxCodes = await client.allTaxCodes.query();
                setTaxCodes(taxCodes.results)
            } catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
            }
            setIsLoading(false)
        };
        fetchData();
    }, []);

    return (
        <>
            <Typography color="primary">{tMSST("ADD ADDITIONAL TAX CODES")}</Typography>
            {loading || isLoading ? (
                <Box height="400px" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress size={80} />
                </Box>
            ) : (
                <>
                    <Divider sx={{ marginBottom: 2 }} />
                    <form onSubmit={formikTaxCode.handleSubmit}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>

                            <div className="grid w-full grid-flow-col grid-rows-2 gap-4 webkit-box">
                                <div className="flex justify-center">
                                    <TextField variant="filled" {...textFieldProps} label={tMSST("Sales Tax Code")} name="codeName"
                                        error={Boolean(getFormikField(formikTaxCode, "codeName").error)}
                                        helperText={getFormikField(formikTaxCode, "codeName").error}
                                        value={getFormikField(formikTaxCode, "codeName").value} onChange={formikTaxCode.handleChange}
                                    />
                                </div>
                                <div className="flex justify-center">
                                    <TextField variant="filled" {...textFieldProps} label={tGC("Description")} name="description"
                                        error={Boolean(getFormikField(formikTaxCode, "description").error)}
                                        helperText={getFormikField(formikTaxCode, "description").error}
                                        value={getFormikField(formikTaxCode, "description").value} onChange={formikTaxCode.handleChange}
                                    />
                                </div>
                                <div className="flex justify-center">
                                    <TextField variant="filled" {...textFieldProps} label={tGC("Tax Rate")+" %"} name="rate" type="number"
                                        error={Boolean(getFormikField(formikTaxCode, "rate").error)}
                                        helperText={getFormikField(formikTaxCode, "rate").error}
                                        value={getFormikField(formikTaxCode, "rate").value} onChange={formikTaxCode.handleChange}
                                    />
                                </div>
                            </div>
                            <Box sx={{ paddingX: 5 }}>
                                <Box sx={{ paddingY: 1, paddingX: 1, display: "flex", justifyContent: "space-between" }}>
                                    {editMode ? (
                                        <>
                                            <Tooltip title={tGlobal("Save")}>
                                                <IconButton size="small" sx={{ p: 0, marginRight: 2 }} color="primary" onClick={handleEditTaxCodeSave}>
                                                    <SaveIcon cursor={"pointer"} fontSize={"large"} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={tGlobal("Cancel")}>
                                                <IconButton size="small" sx={{ p: 0 }} onClick={handleEditTaxCodeCancel}>
                                                    <CancelIcon cursor={"pointer"} fontSize={"large"} />
                                                </IconButton>
                                            </Tooltip></>
                                    ) : (
                                        <Tooltip sx={{ paddingX: 5 }} title={tGlobal("Add Tax code")} placement="bottom">
                                            <AddCircleIcon onClick={() => { formikTaxCode.submitForm() }} cursor={"pointer"} fontSize={"large"} />
                                        </Tooltip>
                                    )}
                                </Box>
                            </Box>
                        </Box >
                    </form>
                    <Typography color="primary" sx={{ marginTop: 2 }} >{tMSST("TAX CODES")}</Typography>
                    <Divider />
                    <div style={{ height: 200, width: "100%" }}>
                        <DataGridPro
                            sx={{
                                border: 0, // Removes all borders
                                "& .MuiDataGrid-cell": {
                                    padding: columnPadding, // Adjust the padding as needed
                                },
                                "& .MuiDataGrid-columnHeader": {
                                    padding: columnPadding, // Adjust the padding as needed
                                }
                            }}
                            hideFooter
                            disableColumnSorting
                            disableColumnFilter
                            rows={taxCodes && taxCodes.map((taxCode) => {
                                return {
                                    id: taxCode.TaxCodeId,
                                    codeName: taxCode.codeName,
                                    TaxCodeId: taxCode.TaxCodeId,
                                    description: taxCode.description,
                                    rate: taxCode.rate,
                                    default: taxCode.default,
                                    updatedAt: taxCode.updatedAt,
                                    createdAt: taxCode.createdAt
                                }
                            })}
                            columns={columns}
                            pageSizeOptions={[taxCodes?.length || 0]} // Make sure this number is equal to or greater than the number of rows to display all at once
                        />
                    </div>
                </>
            )}
        </>
    );
}
