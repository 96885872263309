import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Typography, TextField, Grid, MenuItem, Divider, Button, Box } from "@mui/material";
import type { CustomerCreate, CustomerDeliveryAddress, OrderItem } from "orderflow-lambdas";
import usaStates from "../../../../static/usaStates.json";
import { textFieldProps, textFieldSelectProps } from "../../constants/form";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import { FormikProps } from "formik";
import { getFormikField } from "../../../../util/fornik-helpers";
import { useTranslation } from "react-i18next";
import { DataGridPro } from "@mui/x-data-grid-pro/DataGridPro";
import { GridEventListener, GridActionsCellItem, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridColDef, GridSlotProps, GridToolbarContainer, useGridApiRef } from "@mui/x-data-grid-pro";

const columnPadding = '0 2px'

// We create a new type and add id in it because MUI DataGrid works only with ID
type CustomerDeliveryAddressWithId = CustomerDeliveryAddress & { id: string };

export default function BillingAndDelivery({
    formik,
    viewMode,
}: {
    formik: FormikProps<CustomerCreate>;
    viewMode: boolean;
}): JSX.Element {
    const apiRef = useGridApiRef();
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tGB } = useTranslation([], { keyPrefix: 'team.Global.Buttons' })
    const { t: tAddC } = useTranslation([], { keyPrefix: "team.Modals.Customers.createAndEdit.Add Customer" })
    const { t: tAddCButtons } = useTranslation([], { keyPrefix: "team.Modals.Customers.createAndEdit.Add Customer.Buttons" })

    const [billingCopied, setBillingCopied] = useState(true);
    const [deliveryCopied, setDeliveryCopied] = useState(false);

    const genUUID = () => {
        let uuid = uuidv4()
        return uuid
    }

    // --------- TABLE ---------//
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [deliveryAddresses, setDeliveryAddresses] = useState<CustomerDeliveryAddressWithId[]>([]);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    function EditToolbar() {
        const handleClick = () => {
            const id = genUUID();
            const newRow: CustomerDeliveryAddressWithId = {
                id: id,
                companyName: '',
                address: '',
                state: '',
                city: '',
                zipCode: '',
                country: 'US',
                email: '',
                phone: '',
                preferredCommunication: '',
                contactName: '',
            };
            const updatedAddresses = [...deliveryAddresses || [], newRow];
            setDeliveryAddresses(updatedAddresses)
            formik.setFieldValue("delivery", updatedAddresses.map(({ id, ...rest }) => rest));
        };

        return (
            <GridToolbarContainer>
                <Button
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleClick}
                >
                    {tGB('Add Address')}
                </Button>
            </GridToolbarContainer>
        );
    }

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        const updatedDA = deliveryAddresses.filter((dA) => dA.id !== id)
        setDeliveryAddresses(updatedDA);
        formik.setFieldValue("delivery", updatedDA.map(({ id, ...rest }) => rest));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        // Find the original row in deliveryAddresses
        const originalRow = deliveryAddresses.find((dA) => dA.id === newRow.id);

        if (!originalRow) {
            throw new Error('Original row not found.');
        }

        // Merge the original row with the updated data
        const updatedRow = {
            ...originalRow,
            ...newRow, // Update fields from newRow
        };

        const updatedDA = deliveryAddresses.map((dA) =>
            dA.id === newRow.id ? updatedRow : dA
        );

        // Update the state with the modified row
        setDeliveryAddresses(updatedDA);

        // Update Formik with the updated deliveryAddresses
        formik.setFieldValue("delivery", updatedDA.map(({ id, ...rest }) => rest));

        return updatedRow;
    };


    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };
    const columns: GridColDef[] = [
        { field: 'companyName', headerName: tAddC("Company Name"), editable: true, type: 'string', width: 160 },
        { field: 'address', headerName: tGC("Address"), editable: true, type: 'string', width: 180 },
        {
            field: 'state',
            headerName: 'State',
            type: 'singleSelect',
            editable: true,
            width: 160,
            valueOptions: usaStates.map((s) => ({
              value: s.abbreviation,
              label: s.name,
            })),
            valueFormatter: (value:string) => {
              return value;
            },
          },
        { field: 'city', headerName: tGC("City"), editable: true, width: 120 },
        { field: 'zipCode', headerName: tGC("Zip"), editable: true, width: 100 },
        { field: 'country', headerName: tGC("Country"), editable: false, width: 80 },
        { field: 'email', headerName: tGC("Email"), editable: true, type: 'string', width: 160 },
        { field: 'phone', headerName: tGC("Tel. No."), editable: true, type: 'string', width: 160 },
        { field: 'preferredCommunication', headerName: tGC("Preferred Method of Communication"), editable: true, type: 'string', width: 250 },
        { field: 'contactName', headerName: tGC("Contact Name"), editable: true, type: 'string', width: 160 },
        {
            field: 'actions',
            type: 'actions',
            headerName: tGB('Actions'),
            width: 80,
            resizable: false,
            sortable: false,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            disabled={viewMode}
                            icon={<SaveIcon />}
                            label='Save'
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            disabled={viewMode}
                            icon={<CancelIcon />}
                            label='Cancel'
                            className='textPrimary'
                            onClick={handleCancelClick(id)}
                            color='inherit'
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        disabled={viewMode}
                        icon={<EditIcon />}
                        label='Edit'
                        className='textPrimary'
                        onClick={handleEditClick(id)}
                        color='inherit'
                    />,
                    <GridActionsCellItem
                        disabled={viewMode}
                        icon={<DeleteIcon />}
                        label='Delete'
                        onClick={handleDeleteClick(id)}
                        color='inherit'
                    />,
                ];
            },
        },
    ];

    useEffect(() => {
        setTableLoading(true)
        const updatedDAWithId = formik.values.delivery?.map(deliveryAddress => ({
            ...deliveryAddress,
            id: genUUID()
        }));

        if (updatedDAWithId) {
            setDeliveryAddresses(updatedDAWithId);
        }
        setTableLoading(false)
    }, []);

    // -------------------------//

    const copyCustomerIntoBilling = () => {
        const { other, website, ...customerData } = formik.values.details; // removing unneeded fields

        formik.setValues({
            ...formik.values,
            billing: { ...customerData }
        });
    };

    const copyCustomerIntoDelivery = () => {
        const { other, website, ...customerData } = formik.values.details; // removing unneeded fields

        const newDA: CustomerDeliveryAddressWithId = { ...customerData, id: genUUID() };
        const updatedDA = [...deliveryAddresses || [], newDA];

        setDeliveryAddresses(updatedDA);
        formik.setFieldValue("delivery", updatedDA.map(({ id, ...rest }) => rest));
        setTimeout(() => {
            setDeliveryCopied(false)
        }, 800);
    };

    useEffect(() => {
        // Watching the billing and delivery objects and compare them to the customer object
        const { other, website, ...customerData } = formik.values.details; // removing unneeded fields. Its important do do it here too so if statments work good when compare data
        const billingData = formik.values.billing;
        if (billingCopied && JSON.stringify(billingData) !== JSON.stringify(customerData)) {
            setBillingCopied(false);
        }
    }, [formik.values.billing, formik.values.details, billingCopied]);


    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "2px" }}>
                <Typography color="primary">{tAddC("BILLING")}</Typography>
                {billingCopied ? (
                    <Button disabled={viewMode} variant="outlined" size="small" color="success" startIcon={<CheckIcon />}>{tAddCButtons("Copied")}</Button>
                ) : (
                    <Button disabled={viewMode} variant="outlined" size="small" color="primary" onClick={() => { copyCustomerIntoBilling(); setBillingCopied(true) }}>{tAddCButtons("Copy CUSTOMER Details")}</Button>
                )}
            </Box >
            <Divider sx={{ marginBottom: 1 }} />
            <div className="grid grid-flow-col grid-rows-6 gap-4 webkit-box">
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tAddC("Company Name")} name="billing.companyName" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.companyName').error)}
                        helperText={getFormikField(formik, 'billing.companyName').error}
                        value={getFormikField(formik, 'billing.companyName').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Address")} name="billing.address" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.address').error)}
                        helperText={getFormikField(formik, 'billing.address').error}
                        value={getFormikField(formik, 'billing.address').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("City")} name="billing.city" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.city').error)}
                        helperText={getFormikField(formik, 'billing.city').error}
                        value={getFormikField(formik, 'billing.city').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("State")} select name="billing.state" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.state').error)}
                        helperText={getFormikField(formik, 'billing.state').error}
                        value={getFormikField(formik, 'billing.state').value} onChange={formik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {usaStates.map((state) => (
                            <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                {state.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Zip")} name="billing.zipCode" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.zipCode').error)}
                        helperText={getFormikField(formik, 'billing.zipCode').error}
                        value={getFormikField(formik, 'billing.zipCode').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField disabled variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("Country")} select name="billing.country"
                        error={Boolean(getFormikField(formik, 'billing.country').error)}
                        helperText={getFormikField(formik, 'billing.country').error}
                        value={getFormikField(formik, 'billing.country').value} onChange={formik.handleChange}
                    >
                        <MenuItem value="US">US</MenuItem>
                    </TextField>
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tAddC("Contact Name")} name="billing.contactName" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.contactName').error)}
                        helperText={getFormikField(formik, 'billing.contactName').error}
                        value={getFormikField(formik, 'billing.contactName').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Tel. No.")} name="billing.phone" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.phone').error)}
                        helperText={getFormikField(formik, 'billing.phone').error}
                        value={getFormikField(formik, 'billing.phone').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Email")} name="billing.email" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.email').error)}
                        helperText={getFormikField(formik, 'billing.email').error}
                        value={getFormikField(formik, 'billing.email').value} onChange={formik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tGC("Preferred Method of Communication")} name="billing.preferredCommunication" disabled={viewMode}
                        error={Boolean(getFormikField(formik, 'billing.preferredCommunication').error)}
                        helperText={getFormikField(formik, 'billing.preferredCommunication').error}
                        value={getFormikField(formik, 'billing.preferredCommunication').value} onChange={formik.handleChange}
                    />
                </div>
            </div>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 2, marginBottom: "2px" }}>
                <Typography color="primary">{tAddC("DELIVERY")}</Typography>
                {deliveryCopied ? (
                    <Button disabled={viewMode} variant="outlined" size="small" color="success" startIcon={<CheckIcon />}>{tAddCButtons("Copied")}</Button>
                ) : (
                    <Button disabled={viewMode} variant="outlined" size="small" color="primary" onClick={() => { copyCustomerIntoDelivery(); setDeliveryCopied(true) }}>{tAddCButtons("Copy CUSTOMER Details")}</Button>
                )}
            </Box >
            <Divider sx={{ marginBottom: 1 }} />
            <div className="grid grid-flow-col grid-rows-1 gap-4 webkit-box">
                <div style={{ height: 500, width: '100%', overflowX: 'auto' }}>
                    <DataGridPro
                        loading={tableLoading}
                        apiRef={apiRef}
                        // Turn off row/cell selection on click
                        disableRowSelectionOnClick={viewMode}
                        // Disable column menu
                        disableColumnMenu={viewMode}
                        // Hide the density selector
                        disableDensitySelector={viewMode}
                        // Hide the column selector
                        disableColumnSelector={viewMode}
                        // Optionally disallow multiple-row selection
                        disableMultipleRowSelection={viewMode}
                        // Return false if `viewMode` so cells cannot go into edit mode
                        isCellEditable={(params) => !viewMode}
                        sx={{
                            border: 0, // Removes all borders
                            '& .MuiDataGrid-cell': {
                                padding: columnPadding, // Adjust the padding as needed
                            },
                            '& .MuiDataGrid-columnHeader': {
                                padding: columnPadding, // Adjust the padding as needed
                            }
                        }}
                        onRowEditStop={handleRowEditStop}
                        hideFooter
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        processRowUpdate={processRowUpdate}
                        slots={{ toolbar: EditToolbar }}
                        rows={deliveryAddresses.map((dA) => ({
                            ...dA, // Include all fields
                        }))}
                        columns={columns}
                        pinnedColumns={{ right: ["actions"] }}
                        pageSizeOptions={[deliveryAddresses?.length || 0]} // Make sure this number is equal to or greater than the number of rows to display all at once
                    />
                </div>
            </div>
        </>
    );
}
