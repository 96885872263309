import { Box, Typography, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { buttonMarginSpace, buttonWidth } from "../../constants/form";
import { Routes } from "../../../../util/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "../../../../state";
import { closeModal } from "../../../../state/customerPopupModalSlice";
import { useTranslation } from "react-i18next";
import { openNewCustomerModal } from "../../../../state/customerModalSlice";
import { popupFormWidth } from "./constants";



export default function CreateCustomerPopupModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
    const { t: tCreateC } = useTranslation([], { keyPrefix: "team.Modals.Customers.createAndEdit.Create Customer" })

    const { openCustomerPopupModal } = useSelector(({ customerPopupModalSlice }) => customerPopupModalSlice);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeModal());
    };

    const handleOpenCreateCustomerPopupModal = () => {
        handleClose();
        dispatch(openNewCustomerModal())
    };


    return (
        <Dialog
            open={openCustomerPopupModal}
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick")
                    return;
                handleClose()
            }}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{ style: { width: popupFormWidth } }}
        >
            <>
                <DialogTitle sx={{ m: 0, p: 1 }}>{tCreateC("Create Customer")}</DialogTitle>
                <IconButton color="primary" sx={{ position: "absolute", right: 4, top: 4 }} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ p: 1 }} >
                    <Typography align="center">{tCreateC("To avoid duplicate, it is suggested to search for existing customers.")}</Typography>
                    <Typography align="center">{tCreateC("Search for existing customers?")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Box width="100%" sx={{ display: "flex", justifyContent: "end" }}>
                        <Button variant="outlined" color="primary" sx={{ width: buttonWidth, marginRight: buttonMarginSpace }} onClick={handleClose} >{tGlobal("Cancel")}</Button>
                        <Box>
                            <Button variant="outlined" color="primary" sx={{ width: buttonWidth, marginRight: buttonMarginSpace }} onClick={() => {
                                navigate(Routes.HOME_CUSTOMERS)
                                handleClose()
                            }}>{tGlobal("Search")}</Button>
                            <Button variant="contained" color="primary" sx={{ width: buttonWidth }} onClick={() => { handleOpenCreateCustomerPopupModal() }}>{tGlobal("Create")}</Button>
                        </Box>
                    </Box>
                </DialogActions>
            </>
        </Dialog >
    );
}
